.contact-section {
  max-width: 192rem;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  padding: 10rem 0rem 0rem 0rem;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  &__form-container {
    position: relative;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 10% 45% 45%;
    grid-template-rows: 100%;
    align-items: center;
    z-index: 7;
    padding: 5rem;
    @include respond(phone) {
      padding: 20rem 2rem 2rem 2rem;
      margin-top: 40vh;
      height: 150vh;
      grid-template-columns: 100%;
      grid-template-rows: 20% 65% 15%;
    }
  }
  &__social-media {
    height: 100%;
    padding: 5rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top: 5px solid rgba($color: $color-cta-2, $alpha: 0.8);
    border-bottom: 5px solid rgba($color: $color-cta-3, $alpha: 0.8);
    background: rgba($color: $color-primary-grey, $alpha: 0.25);
    backdrop-filter: blur(1rem);
    box-shadow: $shadow-soft;
    transition: 0.2s box-shadow ease-in-out;
    @include respond(phone) {
      flex-direction: row;

      height: 75%;
      padding: 2rem;
      grid-column: 1/-1;
      grid-row: 3/4;
      // margin-bottom: 10vh;
      margin-top: 5rem;
    }
    &:hover {
      box-shadow: $shadow-soft-hover;
    }

    &--title {
      width: fit-content;
      // text-transform: uppercase;

      font-size: 4vmin;
      letter-spacing: 2px;
      color: $color-cta-2;
      font-weight: 400;
      font-family: $font-primary;
      writing-mode: vertical-rl;
      text-orientation: sideways-right;
      transform: scaleX(1) scaleY(1);
      @include respond(phone) {
        writing-mode: horizontal-tb;
        text-orientation: initial;
        color: transparent;
      }
    }
    &--links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @include respond(phone) {
        flex-direction: row;
      }
    }

    &--icon {
      font-size: 5vmin;
      color: $color-cta-3;
      filter: drop-shadow(0.5vmin 0.5vmin 1px $color-cta-2);

      transition: all 0.2s ease-in-out;
      &:not(:last-child) {
        margin-top: 4vmin;
        @include respond(phone) {
          margin-top: 0;
          margin-left: 8rem;
          font-size: 10rem;
        }
      }
      &:hover {
        filter: drop-shadow(0.25vmin 0.25vmin 1px $color-cta-2);
        transform: scale(1.5);
        @include respond(phone) {
          transform: scale(1);
        }
      }
    }
  }
  &__link {
    position: relative;
    transition: all 0.2s ease-in-out;
  }
  &__link-text {
    font-family: $font-secondary;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(0) scale(0);
    transform-origin: center;
    font-size: 2rem;
    color: $color-cta-2;
    transition: all 0.2s ease-in-out;
  }
  &__left {
    // background-color: rgba($color: $color-primary-grey, $alpha: 0.9);
    // backdrop-filter: blur(1rem);
    padding: 2rem;
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    @include respond(phone) {
      justify-content: center;
      height: 100%;
    }
  }
  &__text-me {
    font-size: 3vmin;
    color: $color-secondary-dark;
    font-family: $font-secondary;
    font-weight: 600;
    position: relative;
    line-height: 2;
    // filter: brightness(80%);
    z-index: 7;
    @include respond(phone) {
      font-size: 4rem;
    }
  }
  &__cta-text {
    color: $color-cta;
    font-family: $font-primary;
    font-size: 12rem;
    letter-spacing: 1rem;
    line-height: 1.5;
    margin-bottom: 2rem;
    display: inline-block;
    @include respond(desk) {
      font-size: 8rem;
    }
  }
  &__email-link {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    color: $color-primary-dark;
    font-weight: 600;
    filter: drop-shadow(0.3rem 0.8rem 0px $color-cta-2)
      drop-shadow(0.6rem 0.8rem 0px rgba($color-cta-3, 0.3));
    display: inline-block;
    transform-origin: center;
    line-height: 1;
    @include respond(phone) {
      font-size: 8rem;
    }
    &:visited {
      color: pink;
      background-color: transparent;
      text-decoration: none;
    }

    &:hover {
      color: $color-cta-3;
      background-color: transparent;
      filter: drop-shadow(0.3rem 0rem 0px $color-cta-2);
      transform: scale(2.5) translateX(3rem);
      @include respond(phone) {
        transform: scale(2.5) translateX(7rem) translateY(3rem);
      }
    }

    &:active {
      color: $color-secondary-dark;
      background-color: transparent;
      text-decoration: underline;
    }
  }
  &__draw-arrow {
    position: absolute;
    top: 100%;
    left: 5vw;
    width: 110%;
    height: 25vmin;
    opacity: 0.7;
    z-index: 7;
    @include respond(desk) {
    }
    @include respond(phone) {
      display: none;
    }
  }
  &__singing-fish {
    position: absolute;

    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scaleX(-1);
    z-index: 0;
    // mix-blend-mode: color-burn;
    opacity: 0.1;
    @include respond(phone) {
      width: 100%;

      opacity: 0.1;
    }
  }
  &__right {
    width: 100%;
    height: 100%;
    padding: 3vmin;
    background-image: $gradient-color;
    backdrop-filter: blur(2px);

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    transform-style: preserve-3d;

    box-shadow: $shadow-soft;
    transition: 0.2s box-shadow ease-in-out;

    border-bottom: 5px solid rgba($color: $color-cta-2, $alpha: 0.8);
    border-top: 5px solid rgba($color: $color-cta-3, $alpha: 0.8);
    @include respond(phone) {
      margin-bottom: 2rem;

      height: 100%;
    }
    &:hover {
      box-shadow: $shadow-soft-hover;
      @include respond(phone) {
        box-shadow: none;
      }
    }
  }
  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2vmin;
    align-items: center;
    justify-content: center;
    @include respond(phone) {
      row-gap: 4rem;
    }
  }
  &--input {
    box-shadow: $shadow-soft;
    font-family: $font-secondary;
    font-size: 1.5vmin;
    width: 100%;
    padding: 1.5vmin 2vmin;
    background-color: rgba($color: $color-primary-grey, $alpha: 0.75);
    border: none;

    transition: all 0.4s ease-in-out;
    color: $color-secondary-dark;
    @include respond(phone) {
      font-size: 4rem;
      padding: 2rem 4rem;
    }

    &:focus {
      border: none;
      overflow: auto;
      outline: none;
      background-color: rgba($color: $color-secondary-dark, $alpha: 0.9);
      box-shadow: $shadow-huge;
      color: $color-primary-grey;
      &::placeholder {
        color: transparent;
      }
    }
    &::placeholder {
      color: $color-secondary-dark;
      letter-spacing: 0.4rem;
      font-family: $font-secondary;
      font-size: 1.5vmin;
      @include respond(phone) {
        font-size: 4rem;
      }
    }
    &:hover {
      box-shadow: $shadow-huge;
      @include respond(phone) {
        box-shadow: none;
      }
    }
  }
  &--input-message {
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: $shadow-soft;
    border: none;

    width: 100%;
    font-size: 1.5vmin;
    resize: none;
    background-color: rgba($color: $color-primary-grey, $alpha: 0.75);
    padding: 1.5vmin 2vmin;
    transition: all 0.4s ease-in-out;
    color: $color-secondary-dark;

    font-family: $font-secondary;
    @include respond(phone) {
      font-size: 4rem;
      padding: 2rem 4rem;
    }
    &:focus {
      background-color: rgba($color: $color-secondary-dark, $alpha: 0.9);
      box-shadow: $shadow-huge;
      color: $color-primary-grey;
      &::placeholder {
        color: transparent;
      }
    }
    &::placeholder {
      color: $color-secondary-dark;
      letter-spacing: 0.4rem;
      font-family: $font-secondary;
      font-size: 1.5vmin;
      @include respond(phone) {
        font-size: 4rem;
      }
    }
    &:hover {
      box-shadow: $shadow-huge;
    }
  }
  &__label {
    font-family: $font-secondary;
    font-weight: 600;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 2vmin;
    letter-spacing: 2px;
    color: $color-primary-dark;
    @include respond(phone) {
      font-size: 4rem;
      margin-bottom: 1rem;
    }
  }

  &__form-message {
    font-size: 1.6rem;
    color: $color-secondary-dark;
    text-align: center;
    font-family: $font-secondary;
    @include respond(phone) {
      font-size: 2.2rem;
    }
  }
}

.animate-arrow-svg {
  animation: draw 20s linear infinite forwards;
}

.contact-section__link:hover .contact-section__link-text {
  transform: translateX(-50%) translateY(4rem) scale(1.5);
  @include respond(phone) {
    display: none;
  }
  @include respond(desk) {
    transform: translateX(-50%) translateY(3rem) scale(0.8);
  }
}
