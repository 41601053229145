//! ===========================
.contact-btn {
  &__button {
    justify-self: center;
    width: fit-content;
    display: block;
    text-decoration: none;
    position: relative;

    margin-bottom: 2vmin;
  }

  &__button &__bottom {
    position: absolute;
    left: 7px;
    top: 7px;
    width: 100%;
    height: 100%;
    background-color: $color-cta-3;
    display: block;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
  }

  &__button &__top {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2vmin 6vmin 1.8vmin 6vmin;
    border: 2px solid $color-cta;
    @include respond(phone) {
      padding: 4rem 12rem 3.6rem 12rem;
    }
  }

  &__button-dark &__top {
    border: 2px solid #fff;
  }

  &__button &__top &__label {
    font-family: $font-secondary;
    font-weight: 600;
    color: $color-cta;
    font-size: 1.8vmin;
    line-height: 110%;
    letter-spacing: 2px;
    text-align: center;
    // text-transform: uppercase;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    @include respond(phone) {
      font-size: 4rem;
    }
  }

  &__button-dark &__top &__label {
    color: #fff;
  }

  &__button:hover &__bottom {
    left: 0;
    top: 0;
    background-color: #f3f3f3;
  }

  &__button:hover &__top &__label {
    color: $color-cta-3;
  }

  &__button-border {
    position: absolute;
    background-color: $color-cta-3;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }

  &__button:hover &__top &__button-border-left,
  &__button:hover &__top &__button-border-right {
    height: calc(100% + 2px);
  }

  &__button:hover &__top &__button-border-top,
  &__button:hover &__top &__button-border-bottom {
    width: calc(100% + 2px);
  }

  &__button-border-left {
    left: -2px;
    bottom: -2px;
    width: 2px;
    height: 0;
  }

  &__button-border-top {
    left: -2px;
    top: -2px;
    width: 0;
    height: 2px;
  }

  &__button-border-right {
    right: -2px;
    top: -2px;
    width: 2px;
    height: 0;
  }

  &__button-border-bottom {
    right: -2px;
    bottom: -2px;
    width: 0;
    height: 2px;
  }
}
