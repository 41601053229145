//! Font Primary

@font-face {
  font-family: 'Didot';
  src: local('Didot'), url('./fonts/TheanoDidot-Regular.ttf');
}

//! Font Secondary
@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url('./fonts/Raleway.ttf');
}

//! Font Secondary oblique
@font-face {
  font-family: 'Raleway-Italic';
  src: local('Raleway-Italic'), url('./fonts/Raleway-Italic.ttf');
}

//! Font calligraphy

@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// * {
//   transform: translate3d(0, 0, 0);
// }

html {
  font-size: 62.5%; /* This way 1rem is 10px */
}
body {
  position: relative;
  box-sizing: border-box;
  color: $color-primary-dark;
  overflow-x: hidden;
  font-family: $font-primary;
  scroll-behavior: smooth;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */

  // &::-webkit-scrollbar {
  //   display: none;
  // }

  &::-webkit-scrollbar {
    width: 0.8rem;
    transition: all 0.2s ease-in-out;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba($color: $color-cta-2, $alpha: 0.5);
    border-radius: 2rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($color: $color-cta-3, $alpha: 0.75);
    border-radius: 2rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-cta;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
.mr-xs {
  margin-right: 1.5rem;
}
.mr-sm {
  margin-right: 2rem;
}
.mr-md {
  margin-right: 3rem;
}
.mt-lg {
  margin-top: 10rem;
}
.mb-md {
  margin-bottom: 5rem;
}
.mb-xl {
  margin-bottom: 10rem;
}
.mb-xs {
  margin-bottom: 1rem;
}

#root {
  background-color: rgba($color: $color-primary-grey, $alpha: 0.9);
}

.display-none {
  display: none;
}

strong {
  color: $color-primary-dark;
}

button,
button:focus {
  outline: none;
  border: none;
}
