//! ==========================================================================
.technologies {
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  padding: 20rem 5rem 2rem 5rem;

  position: relative;

  @include respond(phone) {
    overflow-x: hidden;
  }
  @include respond(phone) {
    padding: 20rem 4rem 2rem 4rem;
  }

  &__title {
    @include section-title;
  }
  &__subtitle {
    @include section-subtitle;
  }
  &__tools-title {
    display: inline-block;
    padding: 0.5rem 2rem;
    border-radius: 5rem;

    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 4rem;
    margin-top: 4rem;
    font-size: 1.8rem;

    @include respond(phone) {
      font-size: 3.2rem;
    }
  }
  &__tools-container {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
    position: relative;
    

    &::before {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      left: 0;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.4;
      width: 100%;
      height: 100%;
    }
    @include respond(phone) {
      grid-template-columns: 1fr;
    }
  }
  &__tools-developer {
    margin-bottom: 20rem;
  }
}
