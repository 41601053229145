.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  background-color: rgb(32, 32, 32);
  color: white;
  font-size: 2rem;
  position: relative;
  z-index: 1;
  letter-spacing: 2px;
  font-family: $font-secondary;
  @include respond(phone) {
    margin-top: 100rem;
  }
}
