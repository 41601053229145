.project-caption {
  &__link-container {
    display: flex;
    align-items: center;
    @include respond(phone) {
      transform: translateY(2px);
    }
  }
  &__link {
    width: 15vmin;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-decoration: none;
    white-space: nowrap;
    @include respond(phone) {
      width: 75%;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }
  &__project-subtitle {
    font-size: 2rem;
    font-family: $font-secondary;
    color: $color-secondary-dark;
    margin-bottom: 2rem;
    @include respond(phone) {
      font-size: 3rem;
    }
  }
  &__subtitle {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 2rem;
    letter-spacing: 2px;

    @include respond(phone) {
      font-size: 3.2rem;
    }
  }
  &__tech {
    &--card {
      font-size: 1.6rem;
      letter-spacing: 1px;
      font-family: $font-secondary;
      color: $color-secondary-dark;

      @include respond(phone) {
        font-size: 2.4rem;
      }
    }
  }
  &__link-text {
    font-family: $font-secondary;
    font-size: 2rem;
    letter-spacing: 4px;
    font-weight: 400;
    transition: all 0.2s ease-in-out;

    @include respond(phone) {
      font-size: 4rem;
    }

    &--atomix {
      color: #fff;
      border: 2px solid #fff;

      &:hover {
        box-shadow: $shadow-huge;
        transform: translateY(-2px) scale(1.25);
      }
    }

    &--felina {
      color: #910c34;
      border: 2px solid #910c34;

      &:hover {
        box-shadow: $shadow-huge;
        transform: translateY(-2px) scale(1.25);
      }
    }
    &--cyber {
      color: #ff0bf3;
      border: 2px solid #ff0bf3;

      &:hover {
        box-shadow: $shadow-huge;
        transform: translateY(-2px) scale(1.25);
      }
    }
    &--nexter {
      color: #19191a;
      border: 2px solid #19191a;

      &:hover {
        box-shadow: $shadow-huge;
        transform: translateY(-2px) scale(1.25);
      }
    }
    &--omnitours {
      color: white;
      border: 2px solid white;

      &:hover {
        box-shadow: $shadow-huge;
        transform: translateY(-2px) scale(1.25);
      }
    }
    &--giphy {
      color: #dfffff;
      border: 2px solid #dfffff;

      &:hover {
        box-shadow: $shadow-huge;
        transform: translateY(-2px) scale(1.25);
      }
    }
  }
  &__filler {
    width: 33.33%;
    padding: 1.5rem 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;
    @include respond(phone) {
      width: 100%;
      padding: 4rem;
    }
  }
  &__title-container {
    display: flex;
    align-items: center;
    @include respond(phone) {
      margin-bottom: 2rem;
    }
  }
  &__project-title {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1;
    color: $color-primary-dark;
    margin-right: 1rem;
    @include respond(phone) {
      font-size: 6rem;
    }

    &--subtitle {
      color: $color-secondary-dark;

      font-size: 1.8rem;
      height: 100%;
      display: inline-block;
      display: flex;
      align-items: center;
      display: none;
    }
  }
  &__project-logo {
    height: 5rem;
    width: auto;
    margin-right: 2.5rem;
  }
  &__project-icon {
    height: 5rem;
    width: 5rem;
    color: #ff0bf3;
    margin-right: 2.5rem;
  }

  &__cyber-icon {
    font-size: 2.5rem;
    margin-right: 1rem;
    color: #ff0bf3;
  }
  &__filler-0 {
    background-image: linear-gradient(180.4deg, #c90063 0.35%, #2d5a87 99.89%);
    @include respond(phone) {
      transform: translateY(2px);
      z-index: 2;
    }
  }
  &__filler-1 {
    background-color: #e4d5c5;
  }
  &__filler-2 {
    background-color: #92ffca;
  }
  &__filler-3 {
    background-color: #ffec36;
  }
  &__filler-4 {
    background-color: #586ff0;
  }
  &__filler-5 {
    background-color: #f666f9;
  }
}
