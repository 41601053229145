.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: $color-secondary-dark, $alpha: 0.9);
  backdrop-filter: blur(1rem);
  width: 100vw;
  height: 100vh;
  padding: 2rem;
  z-index: 1000;
  opacity: 0;
  animation: modal-opacity 0.5s ease-in-out forwards;
  @include respond(phone) {
    padding: 0.5rem 0.5rem 10rem 0.5rem;
  }

  &__close-animation {
    animation: modal-opacity-reverse 0.5s ease-in-out;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  box-shadow: $shadow-huge;
  background-color: $color-primary-grey;
  padding: 4rem;
  position: relative;
  animation: modal-slide-up 0.5s ease-in-out forwards;
  @include respond(phone) {
    padding: 0.5rem;
  }
  &__logo {
    transform: scale(1.5);

    @include respond(desk) {
      transform: scale(1.1);
    }
  }
  &__title {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid rgba($color: $color-palette-1, $alpha: 0.5);
    padding-bottom: 0.5vmin;
    padding: 1vmin;

    @include respond(phone) {
      padding: 4vmin;
    }
    &--image {
      height: 8vmin;
      margin-right: 2vmin;
    }
  }
  &__header-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    &--heading {
      font-size: 3vmin;
      letter-spacing: 1px;
      color: $color-primary-dark;
      @include respond(phone) {
        font-size: 6rem;
      }
    }
    &--caption {
      font-size: 1.5vmin;
      color: $color-secondary-dark;
      font-family: $font-secondary;
      letter-spacing: 1px;
      @include respond(phone) {
        font-size: 2.5rem;
        margin-bottom: 2rem;
      }
    }
  }

  &__tech {
    padding: 0.5vmin;

    &--cards {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-family: $font-secondary;
      letter-spacing: 2px;
      margin-left: -2px;
    }
    &--card {
      margin: 1vmin;
      font-size: 1.4vmin;
      padding: 0.5vmin 1vmin;
      border: 2px solid $color-secondary-grey;
      transition: all 0.2s ease-in-out;
      @include respond(phone) {
        font-size: 2.5rem;
      }

      &:hover {
        transform: scale(1.3);
        color: $color-cta;
        border: 2px solid $color-cta-2;
      }
    }
  }
  &__content {
    display: grid;
    grid-template-columns: 100%;
  }
  &__slider {
    height: 62vh;
    width: calc(100% - 20rem);

    margin: 5rem auto;

    overflow-y: visible;
    z-index: 1000;
    border-left: 5px solid rgba($color: $color-cta-3, $alpha: 0.8);
    border-right: 5px solid rgba($color: $color-cta, $alpha: 0.8);
    padding: 0.5rem;
    display: flex !important;
    align-items: center !important;
    @include respond(desk) {
      height: 48vh;
    }
    @include respond(phone) {
      grid-row: 2/3;
      width: calc(100% - 2rem);
      z-index: 1000;

      height: 60vh;
      border-left: none;
      border-right: none;
      align-self: flex-end;
    }
  }

  &__slide-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    max-width: 172.8rem;

    padding: 4rem 8rem;
    transition: all 0.5s ease-in-out !important;

    @include respond(phone) {
      padding: 2rem 4rem;
      max-width: 99vw;
      height: 100%;
    }
  }
  &__image {
    width: auto;
    max-height: 50vh;
    object-fit: contain;
    filter: drop-shadow(0 5px 10px rgba(166, 173, 201, 0.5));
    position: relative;
    transition: all 0.2s ease-in-out;
    @include respond(phone) {
      width: auto;
      max-height: 25vh;
      object-fit: contain;
    }
  }
  &__btn-container {
    margin: 0 auto;

    @include respond(phone) {
      margin: 10rem auto;
      grid-row: 1/2;
    }
  }

  &__slide-animation {
    animation: 0.5s modal-slide-down ease-in-out;
  }
}

.slick-dots li {
  font-size: 1rem;
}

@keyframes modal-slide-down {
  from {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
  to {
    opacity: 0;
    transform: translateX(0) translateY(100%);
  }
}

@keyframes modal-slide-up {
  from {
    opacity: 0;
    transform: translateX(0) translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

@keyframes modal-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-opacity-reverse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
