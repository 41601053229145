$color-primary-dark: #1e1d1f;
$color-secondary-dark: #494949;

$color-primary-grey: #fefaf6;
$color-secondary-grey: #eeeeee;

$color-cta: #eb487e;
$color-cta-2: #ffc2ff;
$color-cta-3: #2acdc1;

$color-palette-1: #fb47c3;
$color-palette-2: rgb(255, 95, 144);
$color-palette-3: #ff9366;
$color-palette-4: #ffce35;
$color-palette-5: #ffff9d;
$color-palette-6: #febebe;

$font-secondary: 'Raleway', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-secondary-italic: 'Raleway-Italic', 'Helvetica Neue', 'Helvetica, Arial',
  sans-serif;
$font-primary: 'Didot', 'Helvetica Neue', 'Helvetica, Arial', sans-serif;
$font-calligraphy: 'Pinyon Script', 'Helvetica Neue', 'Helvetica, Arial', serif;

$transition-short: 0.25s all ease-in-out;
$transition-medium: 0.5s all ease-in-out;
$transition-large: 1s all ease-in-out;

$shadow-huge: rgba(0, 0, 0, 0.2) 0px 20px 30px;

$shadow-soft: rgba(17, 17, 26, 0.1) 0px 4px 16px,
  rgba(17, 17, 26, 0.05) 0px 8px 32px;
$shadow-soft-hover: 0 2px 2px rgba(154, 160, 185, 0.05),
  0 5px 20px rgba(166, 173, 201, 0.2);

$shadow-small: 0 1.3px 4.4px rgba(0, 0, 0, 0.2),
  0 4.5px 9.4px rgba(0, 0, 0, 0.119), 0 20px 30px rgba(0, 0, 0, 0.081);

$shadow-animation: shadow-show 5s alternate infinite ease-in-out;

$gradient-color: linear-gradient(
  to bottom,
  rgba(255, 255, 157, 0.6),
  rgba(255, 228, 157, 0.6),
  rgba(255, 205, 184, 0.6),
  rgba(255, 193, 223, 0.6),
  rgba(255, 194, 255, 0.6)
);
