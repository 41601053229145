//! Name Box
.name-box {
  position: relative;
  width: npm start;
  height: 12rem;

  display: flex;
  justify-content: center;
  flex-direction: column;
  transform-origin: center;
  .title {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: 8rem;

    transform: rotate(-1.5deg);
    .block {
      width: 0%;

      height: inherit;
      background: rgba($color-cta-3, 0.7);
      position: absolute;
      animation: mainBlock 2s 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
      transform-origin: left;
      &--text {
        text-align: center;
      }
    }

    h1 {
      font-family: $font-calligraphy;
      font-weight: 400;
      color: $color-primary-dark;
      font-size: 8rem;
      -webkit-animation: mainFadeIn 2s forwards;
      -o-animation: mainFadeIn 2s forwards;
      animation: mainFadeIn 2s forwards;
      animation-delay: 2.6s;
      opacity: 0;
      display: flex;
      align-items: baseline;
      position: relative;

      span {
        width: 0px;
        height: 0px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;

        background: $color-cta-3;
        -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92)
          forwards;
        animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
        animation-delay: 3s;
        margin-left: 0.5rem;
        margin-top: -1rem;
        position: absolute;
        bottom: 1.3rem;
        right: -1.2rem;
      }
    }
  }

  .role {
    overflow: hidden;

    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    // margin-left: 0.5rem;
    margin-top: 0rem;

    display: inline-block;
    transform: rotate(-1.5deg);

    .block {
      width: 0%;

      transform-origin: left;
      height: inherit;
      background: rgba($color: $color-cta, $alpha: 0.8);
      position: absolute;
      animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
      animation-delay: 3s;
      display: flex;
    }

    p {
      margin-top: 1rem;
      animation: secFadeIn 1.8s forwards;
      animation-delay: 4.2s;
      opacity: 0;
      font-weight: 600;
      font-family: $font-secondary-italic;
      color: $color-cta;
      font-size: 2.6rem;
      text-transform: uppercase;
      letter-spacing: 0.75rem;
      margin-left: 0.5rem;
      text-align: center;
    }
  }
}

@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: $color-cta;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 1rem;
    height: 1rem;
    background: $color-cta;
    opacity: 1;
    bottom: 4.5rem;
  }
  65% {
    width: 0.7rem;
    height: 0.7rem;
    bottom: 0rem;
    width: 1.5rem;
  }
  80% {
    width: 1.2rem;
    height: 1.2rem;
    bottom: 2.4rem;
  }
  100% {
    width: 1rem;
    height: 1rem;
    background: $color-cta-3;
    border: 0px solid #222;
    bottom: 3rem;
    right: -0.75rem;
  }
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    top: 1.25rem;
    width: 0%;
    left: 0;
  }
  50% {
    top: 1.25rem;
    width: 100%;
    left: 0;
  }
  100% {
    top: 1.25rem;
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
