:root {
  --level-one: translateZ(3rem);
  --level-two: translateZ(6rem);
  --level-three: translateZ(9rem);
  --level-four: translateZ(12rem);

  --fw-normal: 400;
  --fw-bold: 800;
}

.sections-container {
  position: relative;
  width: 100%;
}

// #technologies-section {
//   min-height: 100vh;
//   padding-top: 10rem;
// }
//! Full page animation
