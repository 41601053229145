//! ========================================

.work-section {
  max-width: 108rem;
  margin: 0 auto;
  padding: 20rem 5rem 0 5rem;
  z-index: 2;
  position: relative;
  @include respond(phone) {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  &__project {
    margin: 0 auto;
    max-width: 108rem;
    flex-grow: 1;
    display: flex;
    margin-bottom: 10rem;
    transition: box-shadow 0.5s ease-in-out;
    box-shadow: $shadow-soft;
    &:hover {
      box-shadow: $shadow-huge;
    }
    @include respond(phone) {
      flex-direction: column;
      max-width: 100vw;

      margin-bottom: 12rem;
    }
  }

  &__title {
    @include section-title;
    margin-bottom: 10rem;

    &--span {
      margin-left: 20vmin;
    }
  }
  &__subtitle {
    @include section-subtitle;
  }
  &__subtitle:last-child {
    margin-bottom: 15rem;
  }
}
.cyber-heart-animation {
  animation: heartbeat-lower 1s infinite;
}

@keyframes heartbeat-lower {
  0% {
    transform: scale(0.85);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.85);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(0.85);
  }
}
