.tech-icon {
  display: flex;
  margin-bottom: 4rem;
  @include respond(phone) {
    margin-bottom: 8rem;
  }

  &__image-container {
    transition: all 0.2s ease-in-out;
    height: 14rem;
    width: 14rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    box-shadow: $shadow-soft;
    background: linear-gradient(
      145deg,
      $color-primary-grey,
      $color-secondary-grey
    );
    transform: perspective(300px) rotateX(25deg) rotateY(15deg) rotateZ(-15deg);
    @include respond(phone) {
      height: 24rem;
      width: 24rem;
      padding: 4rem;
    }
  }
  &__image {
    height: 10rem;
    width: 10rem;
    object-fit: contain;
    @include respond(phone) {
      height: 20rem;
      width: 20rem;
    }
  }
  &__text-container {
    position: relative;
    z-index: 2;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    @include respond(phone) {
      margin-left: 4rem;
    }
  }
  &__title {
    font-size: 2.8rem;
    display: flex;
    align-items: center;
    @include respond(phone) {
      font-size: 5.6rem;
    }
  }
  &__subtitle {
    font-size: 1.8rem;
    color: $color-secondary-dark;
    font-family: $font-secondary;
    @include respond(phone) {
      font-size: 3.2rem;
    }
  }
  &__external-link {
    display: flex;
    align-items: center;
    color: $color-secondary-dark;
    opacity: 0;
    transition: opacity 0.4s ease-out;
  }
}

.tech-icon:hover .tech-icon__image-container {
  transform: scale(1.05) perspective(300px) rotateX(0deg) rotateY(0)
    rotateZ(0deg);
  box-shadow: $shadow-huge;
  background: linear-gradient(
    190deg,
    $color-primary-grey,
    $color-secondary-grey
  );
}
.tech-icon:hover .tech-icon__text-container {
  transform: scale(1.1) translateX(2rem);
}
.tech-icon:hover .tech-icon__external-link {
  opacity: 1;
}
