.project-card {
  width: 66.66%;

  overflow: hidden;

  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
  @include respond(phone) {
    width: 100%;
    transform: translateY(-2px);
  }
  &__img-container {
    max-width: 108rem;
    max-height: 48.5rem;

    overflow: hidden;
    @include respond(phone) {
      display: flex;
      align-items: center;
      overflow-y: hidden;
      max-height: unset;
    }
  }
  &__image {
    max-height: 100%;
    max-width: 100%;
    object-position: left;
    object-fit: cover;
    transition: transform 1s ease-in-out;
    @include respond(phone) {
      width: 100%;
    }
  }
}
.project-card:hover .project-card__image {
  transform: scale(1.025);
  @include respond(phone) {
    transform: unset;
  }
}

.thumb-vertical {
  background-color: rgba($color: $color-cta-3, $alpha: 0.75);
  width: 100% !important;
  border-radius: 2rem;
}

.track-vertical {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 90%;
  width: 0.8rem !important;
  border-radius: 2rem;

  background-color: rgba($color: $color-cta-2, $alpha: 0.5);
}
