//! Navbar ========================================================================

.navbar {
  background: $color-palette-5;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 10rem;

  width: 100%;

  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 5rem;

  transform-origin: center;
  &__abstract-eye-container {
    position: absolute;
    top: 0;
    left: 3rem;
    opacity: 0;
    z-index: 10;
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    transform: scaleX(-1) rotate(15deg);
    animation: eye-pop-up 1s 1s ease-out forwards;
    @include respond(phone) {
      top: 0;
      left: 1rem;
      height: 20rem;
      width: 20rem;
    }
  }
  @keyframes eye-pop-up {
    from {
      opacity: 0;
      display: none;
    }
    to {
      opacity: 1;
      display: block;
    }
  }
  &__abstract-eye {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    object-position: left;
  }
  &__name-logo {
    transform: scale(0.5);
    position: fixed;
    top: -0.5rem;
    left: 0;
    display: flex;
    justify-content: center;
    pointer-events: none;
    z-index: 1000;
    width: 100%;
    height: 10rem;
    @include respond(phone) {
      top: 5rem;
      transform: scale(1);
    }
  }
  @include respond(phone) {
    height: 20rem;
  }
  &__links-container {
    @include respond(desk) {
      display: none;
    }
  }
  &__text {
    color: $color-cta;
    font-family: $font-secondary;
    letter-spacing: 0.2rem;
    font-weight: 600;

    transform-style: preserve-3d;
  }
  &__cta {
    color: $color-cta-3;
  }
  &__ScrollLink {
    position: relative;
    padding: 1.8rem 2rem;
    border: none;
    background-color: transparent;

    outline: none;
    font-size: 1.6rem;

    transform-style: preserve-3d;
    display: inline-block;
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.25);
      @include respond(desk) {
        transform: scale(1);
      }
    }
    @include respond(desk) {
      margin-bottom: 4rem;
    }
  }

  //! Links ================================================================
  &__type1 {
    color: $color-secondary-dark;
  }
  // Effect one
  &__type1::after,
  &__type1::before {
    content: '';
    display: block;
    position: absolute;
    width: 10%;
    height: 15%;
    border: 2px solid;
    transition: all 0.6s ease;
    border-radius: 2px;
    @include respond(desk) {
      border: 5px solid;
    }
  }

  &__type1::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $color-secondary-dark;
    border-right-color: $color-secondary-dark;
  }

  &__type1::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: $color-secondary-dark;
    border-left-color: $color-secondary-dark;
  }

  &__type1:hover:after,
  &__type1:hover:before {
    width: 105%;
    height: 107%;
  }

  &__type3 {
    color: $color-cta;
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__type3::after,
  &__type3::before {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    border: 3px solid;
    transition: all 0.6s ease;
    border-radius: 2px;
    @include respond(desk) {
      border: 5px solid;
    }
  }

  &__type3::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $color-cta-3;
    border-right-color: $color-cta-3;
  }

  &__type3::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: $color-cta;
    border-left-color: $color-cta;
  }

  &__type3:hover::after,
  &__type3:hover::before {
    border-bottom-color: $color-cta-3;
    border-right-color: $color-cta-3;
    border-top-color: $color-cta;
    border-left-color: $color-cta;
    width: 95%;
    height: 90%;
  }
  //! active link ================================================================
  &__active-link {
    color: $color-primary-dark;
  }
  &__active-link::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $color-primary-dark;
    border-right-color: $color-primary-dark;
    width: 105%;
    height: 107%;
  }

  &__active-link::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: $color-primary-dark;
    border-left-color: $color-primary-dark;
    width: 105%;
    height: 107%;
  }
  &__active-link-cta::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $color-cta-3;
    border-right-color: $color-cta-3;
    width: 100%;
    height: 100%;
  }

  &__active-link-cta::before {
    top: 0;
    left: 0;
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: $color-cta;
    border-left-color: $color-cta;
    width: 100%;
    height: 100%;
  }
  //! ================================================================

  //! Hamburger menu==================================================
  &__hamburger {
    display: none;
    @include respond(desk) {
      display: inline-block;
    }
    @include respond(phone) {
      display: inline-block;
      transform: scale(1.5);
      transform-origin: right;
    }
  }
  .hamburger-menu {
    position: fixed;
    top: 9.9rem;
    left: 0;

    border-radius: 2rem;
    background-color: rgba($color: $color-palette-5, $alpha: 0.9);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    opacity: 1;

    z-index: 14;
    animation: menu-dropdown 0.4s ease-in-out;

    transition: all 0.2s ease-in-out;

    height: calc(100vh - 9.9rem);
    width: 100vw;

    backdrop-filter: blur(1rem);
    @include respond(phone) {
      top: 19.9rem;
      height: calc(100vh - 19.9rem);
    }
  }
  @keyframes menu-dropdown {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  &__custom-menu-link {
    font-size: 4vmin;
    @include respond(phone) {
      font-size: 8rem;
      padding: 4rem 8rem;
    }
  }
  &__abstract-idea {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    object-fit: contain;
    transform: scaleY(-1);

    opacity: 0.05;
    z-index: 13;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    @include respond(phone) {
      object-position: center 75%;
      opacity: 0.15;
    }
  }
}

//! change the default pointer cursor for react-hamburger so the custom mouse pointer can be used
.hamburger-react {
  cursor: none !important;
}
