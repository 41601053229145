@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (min-device-width: 320px) and (max-device-width: 480px) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      @content;
    }
  }

  @if $breakpoint == desk {
    @media (max-width: 1479px) {
      @content;
    }
  }
}

@mixin section-subtitle {
  letter-spacing: 1px;
  font-size: 1.8vmin;
  color: $color-secondary-dark;
  font-family: $font-secondary;

  margin-left: 2rem;

  @include respond(phone) {
    font-size: 3vmin;

    margin-top: 4vmin;
  }
}

@mixin section-title {
  font-family: $font-primary;
  line-height: 1;
  letter-spacing: 5px;
  text-align: left;

  width: 100%;
  height: 100%;
  font-size: 6vmin;
  margin-bottom: 2rem;

  white-space: nowrap;
  color: $color-primary-dark;

  opacity: 1;
  @include respond(phone) {
    font-size: 8rem;
    margin-bottom: 6vmin;

    margin-top: 8rem;
  }
}
