//! Text Overlay
:root {
  --colorMain: white;
  --brShadow: -0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
  --tlShadow: 0.2rem -0.2rem 0.5rem rgba(255, 255, 255, 1);
}

.hexagon {
  opacity: 0.2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.35);
  width: 40vmin;
  height: 23.094vmin;
  background-image: linear-gradient(
    to bottom,
    rgb(42, 205, 193),
    rgb(22, 209, 242),
    rgb(117, 208, 255),
    rgb(196, 201, 255),
    rgb(255, 194, 255)
  );

  z-index: 1;
  filter: blur(0.5rem);

  animation: rotate 27s 3.5s infinite ease-in-out reverse;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 20vmin solid transparent;
  border-right: 20vmin solid transparent;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 11.548vmin solid #2acdc1;
}

.hexagon:after {
  top: 100%;
  width: -2;
  border-top: 11.548vmin solid #ffc2ff;
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg) scale(1.35);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg) scale(1.35);
  }
}

//! Name with animation (just below the navbar)
.header {
  grid-row: 1/2;
  grid-column: 1/-1;
  position: relative;
  width: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    $color-palette-5,
    $color-palette-5,
    rgb(255, 240, 157)
  );

  backdrop-filter: blur(2rem);
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;

  transform-origin: bottom;
  overflow-x: hidden;
  @include respond(phone) {
    height: 25vh;

    padding-top: 10rem;
    border-bottom-left-radius: 70% 70%;
    border-bottom-right-radius: 70% 70%;
  }
  &__name-logo {
    @include respond(desk) {
      transform: scale(0.8);
      margin-top: 8rem;
    }
    @include respond(phone) {
      margin-top: 0rem;

      transform: scale(1.5);
    }
  }
}

//! Text image and button for presentation

.hero {
  width: 100%;

  min-height: 102.4rem;

  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;

  padding-bottom: 0rem;
  z-index: 2;
  transform-origin: top;

  @include respond(phone) {
    width: 100vw;
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
  }

  &__main-content {
    min-height: 70vh;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 192rem;
    margin: 0 auto;
    padding: 5rem;

    @include respond(phone) {
      flex-direction: column;
      height: 75vh;
      justify-content: flex-start;
    }
  }

  &__span-special {
    color: $color-cta;
    font-family: $font-secondary;
    font-weight: 600;
    letter-spacing: 0.2rem;
    width: 100%;
    display: inline;
    white-space: nowrap;
  }

  &__text-container {
    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    @include respond(phone) {
      width: 100%;
      height: 25%;
    }
  }
  &__title {
    font-size: 8vmin;
    color: $color-primary-dark;
    transform: translateX(0) rotate(0) scale(1);
    display: inline-block;
    width: 100%;
    text-align: center;
    animation: messageBox 1s ease-in-out;
    @include respond(phone) {
      font-size: 8rem;
    }
    &--line2 {
      font-size: 8vmin;
      width: 100%;
      margin-bottom: 20rem;
      text-align: center;
      color: $color-secondary-dark;
    }
    &--sub {
      font-family: $font-secondary;
      color: $color-secondary-dark;
      display: inline-block;
      font-size: 4vmin;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;

      @include respond(phone) {
        font-size: 5rem;
      }
    }
    &--sub-cta {
      color: $color-cta-3;
    }
  }
  &__images-container {
    height: 100%;
    width: 50%;
    position: relative;

    @include respond(phone) {
      width: 100%;
      height: 50%;
    }
  }
  &__image-container {
    width: 100%;
    height: 100%;
    background-image: url(https://res.cloudinary.com/c1oud9/image/upload/v1622149732/portfolio/abstract-face-cta-3-collor_ak2kab.svg);

    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    z-index: 22;
    transition: all 0.5s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;

      background-image: url(https://res.cloudinary.com/c1oud9/image/upload/v1622149722/portfolio/abstract-face_bilniy.svg);
      transform: scale(1.025) translate(-2%);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 21;
      opacity: 0.75;
      animation: move-x-axis 10s 1s alternate infinite ease-in-out;
      transition: all 0.5s ease-in-out;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;

      transform: scale(0.975) translate(2%);

      background-image: url(https://res.cloudinary.com/c1oud9/image/upload/v1622149743/portfolio/abstract-face-cta-collor_grmai7.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 20;
      opacity: 0.75;
      animation: move-y-axis 10s 1s alternate infinite ease-in-out;
      transition: all 0.5s ease-in-out;
    }
    @include respond(phone) {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  @keyframes move-x-axis {
    from {
      transform: translate(-2%) scale(1.025);
    }
    to {
      transform: translate(2%) scale(0.975);
    }
  }

  @keyframes move-y-axis {
    from {
      transform: translate(2%) scale(0.975);
    }
    to {
      transform: translate(-2%) scale(1.025);
    }
  }
  &__story-container {
    grid-column: 1/-1;
    margin: 20rem auto 40rem auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include respond(phone) {
      margin: 0rem auto 10rem auto;
    }
  }
  &__infinite-text {
    margin-top: 20rem;
    margin-bottom: 10rem;
    display: flex;
    font-size: 2.8vmin;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;
    @include respond(phone) {
      font-size: 5rem;
      height: 40rem;
    }
  }
  &__about-btn {
    transform: scale(1.25);
    margin-bottom: 20rem;
    @include respond(phone) {
      margin: 10rem auto 10rem auto;
    }
  }
}
