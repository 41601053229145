.close {
  &__close-container {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: max-content;
    @include respond(phone) {
      right: 2rem;
      left: unset;
    }
  }
  &__close {
    margin-top: 2rem;
    margin-left: 2rem;
    height: 6rem;
    width: 6rem;
    transition: transform 0.2s ease-in-out;
    fill: $color-cta;
    &:hover {
      transform: scale(1.5) rotate(5deg);
    }
  }
  &__close-text {
    color: $color-cta-2;
    font-size: 2rem;
    font-family: $font-secondary-italic;
    position: absolute;
    top: 4rem;
    left: 7.5rem;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.2s ease-in-out;
  }
}

.close__close-container:hover .close__close-text {
  transform: scaleX(1) translateX(2rem) scale(1.5);
  @include respond(phone) {
    display: none;
  }
}
