.download-cv {
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%) scale(1);
  &__link {
    font-size: 4rem;
    color: $color-cta;
    font-family: $font-secondary;
    text-decoration: none;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.5);
    }
  }
}
#about-top {
  position: relative;
}
.about {
  max-width: 144rem;
  margin: 0 auto;

  min-height: 100vh;
  &__go-back {
    height: 8rem;
    margin-top: 4rem;
    margin-left: 4rem;
    transform: translateX(-1rem) rotate(0) scale(1);
    transition: all 0.2s ease-in-out;
    @include respond(phone) {
      margin-left: 4rem;
    }

    &--text {
      color: $color-cta-2;
      font-size: 2rem;
      font-family: $font-secondary-italic;
      position: absolute;
      top: 50%;
      left: 7.5rem;
      transform: scaleX(0) translateX(-50%);
      transform-origin: left;
      transition: transform 0.2s ease-in-out;
    }
    &:hover {
      transform: translateX(-1rem) rotate(5deg) scale(1.5);
      @include respond(phone) {
        transform: translateX(-1rem) rotate(5deg) scale(2);
      }
    }
  }

  &__presentation {
    display: flex;
    align-items: center;
    max-width: 144rem;
    padding-top: 20rem;
    @include respond(phone) {
      flex-direction: column;
      padding: 6rem;
    }
  }
  &__portrait-container {
    width: 100%;
    filter: drop-shadow(0 52px 74px rgba(0, 21, 64, 0.2));

    @include respond(phone) {
      padding: 6rem;
    }
  }
  &__portrait {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__text-container {
    padding: 2rem;
  }
  &__paragraph-important {
    font-weight: 600;
    text-align: justify;
    text-justify: newspaper;
    font-size: 4rem;
    color: $color-primary-dark;
    margin-bottom: 4rem;
    @include respond(phone) {
      font-size: 5rem;
    }
  }
  &__underline {
    position: relative;

    &::after {
      z-index: 5;
      content: "";
      width: 100%;
      position: absolute;
      bottom: -6px;
      height: 4px;
      left: 0;
      display: block;
      background: $color-cta-3;
      transform: rotate(2deg);
      opacity: 0.75;
    }
    &--second {
      &::after {
        background: $color-cta-2;
        transform: rotate(-0.5deg);
      }
    }
    &--third {
      &::after {
        background: $color-cta;
        transform: rotate(-2deg);
      }
    }
    &--forth {
      &::after {
        background: $color-palette-4;
        transform: rotate(2deg);
      }
    }
    &--fifth {
      &::after {
        background: $color-palette-3;
        transform: rotate(2deg);
      }
    }
  }
  &__paragraph {
    text-align: justify;
    text-justify: newspaper;
    margin-bottom: 2rem;
    text-indent: 3rem;
    font-size: 2rem;
    font-weight: 400;
    font-family: $font-secondary;
    color: $color-secondary-dark;
    line-height: 2;
    @include respond(phone) {
      font-size: 3rem;
      margin-bottom: 4rem;
      line-height: 1.8;
    }
  }
  &__paragraph-timeline-important {
    text-align: justify;
    text-justify: newspaper;
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    @include respond(phone) {
      font-size: 3rem !important;
    }
  }
  &__paragraph-timeline {
    text-align: justify;
    text-justify: newspaper;
    font-size: 1.7rem !important;
    color: $color-secondary-dark !important;
    text-indent: 2rem;
    font-family: $font-secondary;
    @include respond(phone) {
      font-size: 3rem !important;
      margin-bottom: 4rem !important;
      line-height: 1.8 !important;
    }
    ul {
      list-style: none;
      padding-left: 4rem;
      list-style-position: outside;
      li {
        &::before {
          content: "\2022";
          color: $color-cta;
          width: 1em; /* Also needed for space (tweak if needed) */
          margin-right: 1rem; /* Also needed for space (tweak if needed) */
        }
      }
    }
  }
  &__timeline-container {
    width: 100%;
    @include respond(phone) {
      width: calc(100vw - 6rem);
      overflow-x: hidden;
    }
  }
  &__timeline-title {
    font-family: $font-primary;
    font-size: 6rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
  &__back-to-top-container {
    position: relative;
    width: min-content;
    display: inline-block;
    margin-bottom: 4rem;
  }
  &__back-to-top {
    transform: rotate(90deg);
    margin-top: 2rem;
    margin-left: 2rem;
    height: 8rem;
    transition: transform 0.2s ease-in-out;
    @include respond(phone) {
      margin-top: 6rem;
      margin-left: 6rem;
      margin-bottom: 8rem;
      transform: rotate(90deg) translateX(-1rem) scale(2);
    }

    &:hover {
      transform: rotate(90deg) translateX(-1rem) scale(1.5);
      @include respond(phone) {
        transform: rotate(90deg) translateX(-1rem) scale(2);
      }
    }
    &--text {
      color: $color-cta-2;
      font-size: 2rem;
      font-family: $font-secondary-italic;
      position: absolute;
      top: 50%;
      left: 7.5rem;
      transform: scaleX(0) translateX(-50%);
      transform-origin: left;
      transition: transform 0.2s ease-in-out;
    }
  }
}

#about-top:hover .about__go-back--text {
  transform: scaleX(1) translateX(6rem) scale(1.5);
}

.about__back-to-top-container:hover .about__back-to-top--text {
  transform: scaleX(1) translateX(3.5rem) scale(1.5);
  @include respond(phone) {
    display: none;
  }
}

//! Timeline elements
.vertical-timeline-element-subtitle {
  font-family: $font-secondary-italic !important;
  color: $color-secondary-dark !important;

  font-size: 1.6rem !important;
  letter-spacing: 0.2rem;
}
.vertical-timeline-element-content {
  height: min-content;
  box-shadow: $shadow-soft !important;
  border-radius: 0rem !important;
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: $shadow-huge !important;
  }
  @include respond(phone) {
    box-shadow: $shadow-huge !important;
    padding: 4rem !important;
  }
}

.vertical-timeline-element-title {
  font-size: 2rem !important;
  font-family: $font-primary;
  @include respond(phone) {
    font-size: 4rem !important;
  }
}

.vertical-timeline-element-date {
  font-size: 2rem !important;
  font-family: $font-primary !important;
  color: $color-primary-dark !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  @include respond(phone) {
    font-size: 5rem !important;
    display: block;
    width: 100%;
    text-align: center;
  }
}
